/* eslint-disable import/first */

/**
 * @copyright © 2020, Starberry Ltd
 */

 import React from 'react';
 
 // Used to parse <svg> into components.  This can be done simply using
 // `<div dangerouslySetInnerHTML={{__html:image}} />` but this is neater.
 // I'm not sure which is better.  I am worried that this is less efficient
 // than creating a static element and letting babel/webpack have at it.
 import Parser from 'html-react-parser';
 
 // There are also issues when it comes to sizing of inline SVG in HTML.
 
 //  import ImageImg from '../Image/ImageImg';
 
 // Problems with Flexbox in My Account, so using Grid for this instead.
 //import Flexbox from '../Flexbox';
 import Grid from '@mui/material/Grid';
 
 //  import { deepmerge } from '../../deepmerge';
 import {
     makeStyles,
     withSubtheme,
     deepmerge,
     ImageImg,
 } from '../../../StarberryComponentsMui';
 
 //  import { makeStyles } from '../../muiTheming';
 //  import withSubtheme from "../../withSubtheme";

 const useStyles = makeStyles((theme) => ({
     root: (css) => (css)
 }));
 
 // This is the implementation for both Logo and Logotype, as the two
 // differ only by what keys they look for in the theme.
 export const LogoOrLogotype = withSubtheme( (props) => {
     let {
         wrapper, type1, type2, type3,
         theme,
         themeClasses,
         style,
         width, height,
         size,
         className,
         altClassName,
         sx,
         ...rest
     } = props;
 
     let styles = deepmerge({}, sx ?? {}, style ?? {});
 
     let key;
     let classes = new Set();
     let classNames = () => [...classes].filter(x=>x).join(' ');
 
     if (altClassName) {
         className = className + ' ' + altClassName;
     }
 
     let wrapFn;
     if (false === wrapper)
         wrapFn = (x) => (x);
     else
 //        wrapFn = (x) => <Flexbox item style={styles} className={classNames()} {...rest}>{x}</Flexbox>;
         wrapFn = (x) => <Grid className={classNames()}>{x}</Grid>;
 
     let image = theme.getProp((key=type1));
     if (undefined === image) {
         image = theme.getProp((key=type2));
         if (undefined === image) {
             image = theme.getProp((key=type3));
             if (undefined === image) {
                 key = undefined;
                 classes.push(className);
                 if (rest.children)
                     return wrapFn(rest.children);
                 else
                     return undefined;
             }
         }
     }
 
     // Build class names from makeStyles + className (from props)
     classes.add(themeClasses?.root).add(className);
 
     // See if what we have is inline SVG, as used in a theme right now.
     if (image.match(/^\s*(<\?xml[^>]+>)?\s*<svg/)) {
 
         // Force SVG to work within the container given to it; effectively
         // set `<svg style="width:100%; height:auto"...`
         classes.add(useStyles({ "& svg": { width: "100%", height: "auto" } }).root);
 
         if (size) {
             // Hngh.
             width = size;
         }
         if (width) {
             styles.width = width;
             if (height)
                 styles.height = height;
             else
                 styles.height = 'auto';
         }
         else if (height) {
             styles.height = height;
             if (width)
                 styles.width = width;
             else
                 styles.width = 'auto';
         }
 
         // Convert style to class.  I guess we could just use style={{styles}}
         classes.add(useStyles(styles).root);
 
         /*
         if (width)
             image = image.replace(/(<svg[^>]*?)((\swidth=[^\s>]+)?(\s?[^>]+>))/, `$1 width=${width}$4`);
 
         if (height)
             image = image.replace(/(<svg[^>]*?)((\sheight=[^\s>]+)?(\s?[^>]+>))/, `$1 height=${height}$4`);
 
         // Force a relatively sane width and height.
         if (!noforce)
             image = image.replace(/(<svg[^>]*)>/, `$1 style="width:100%;height:auto">`);
         */
 
         return wrapFn(
             Parser(image)
         );
     }
     else {
         return wrapFn(
             <ImageImg group={type1} src={image} width={width} height={height} alt={`Logo`}/>
         );
     }
 }, 'logo');
 
 export const Logo = (props) => (
    LogoOrLogotype({type1: 'logo', type2: 'logotype', type3: 'logoSecondary', ...props})
);
 
 Logo.propTypes = {
 //    wrapper: PropTypes.bool
 };
 
 Logo.defaultProps = {
     //    wrapper: true
 };
 
 export default Logo;
 