import React from "react";
import GlobalLayout from "../core/components/MyAccount/GlobalLayout"
import ErrorPage from "../core/components/MyAccount/404page";

const NotFoundPage = () => (
    <GlobalLayout>
        <ErrorPage />
    </GlobalLayout>
);

export default React.memo(NotFoundPage);
