export default {
    "props": {
        "titleProps": {
            "variant": "h3",
        },
        "textProps": {

        },
        "iconProps": {
            "iconName": "errorPage",
            "iconStyle": {
                "fontSize": "80px",
                "opacity": 0.2,
            }
        },
        "titleText": "The page you’re after seems to be missing.",
        "subtitleText": "This is maybe because it has been removed, we’ve changed it’s name or it is temporarily unavailable.",
        "btnLabel": "Go to Dashboard",
    },
    "sx": (theme) => ({
        "display": "flex",
        "flexDirection": "column",
        "alignItems": "center",
        "textAlign": "center",
        "paddingTop": theme.spacing(5),
        [theme.breakpoints.up('sm')]: {
            "paddingTop": theme.spacing(10),
        },
        "& .error-title": {
            "marginTop": theme.spacing(1),
            "fontSize": theme.h5,
            "lineHeight": theme.h5LineHm,
            [theme.breakpoints.up('sm')]: {
                "fontSize": theme.h3,
                "lineHeight": theme.h3LineHm,
            } 
        },
        "& .error-text": {
            "marginTop": theme.spacing(1),
            "marginBottom": theme.spacing(2),
            "fontSize": theme.bodySmall,
            [theme.breakpoints.up('sm')]: {
                "fontSize": theme.bodyMedium,
            },
        },
    }),
}